import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../components/firebase/firebase";

import Logo from "../../components/logo/Logo";
import "./start.css";

function Start() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="Start">
      <div className="LogoDiv">
        <Logo />
        <div className="ButtonDiv">
          <button
            className="StartButton"
            onClick={() => navigate("/generator")}
          >
            Generate Images
          </button>
          <button className="StartButton" onClick={() => navigate("/metadata")}>
            Generate Metadata
          </button>
          <button
            className="StartButton"
            onClick={() => navigate("/experimental")}
          >
            Experimental
          </button>
          <button
            className="StartButton"
            onClick={() => navigate("/html")}
          >
            HTML Generator
          </button>
          <button
            className="StartButton"
            onClick={() => navigate("/pgp")}
          >
            PGP Tools
          </button>
        </div>
      </div>
    </div>
  );
}

export default Start;
