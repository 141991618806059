import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  type: 'default'
};

export const snackbarSplice = createSlice({
  name: 'snackbar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
})

export const { setOpen, setMessage, setType } = snackbarSplice.actions;

export default snackbarSplice.reducer;