import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "../generator.css";
import { auth } from "../../../components/firebase/firebase";
import NextButton from "../components/NextButton";
import SaveButton from "../components/SaveButton";
import CancelButton from "../components/CancelButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import StepperNav from "../components/StepperNav";
import { setCollectionInfo } from "../../../components/redux/reduxSlice";
const CustomCheckbox = styled(Checkbox)({
  color: "#dbdbdb",
  flex: 1,
  "&.Mui-checked": {
    color: "#dbdbdb",
  },
});

function GeneratorStart(props) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={0} />
      </div>
      <div className="GeneratorMid">
        <TextField
          className="InputForm"
          focused
          label="Name"
          variant="outlined"
          sx={{ marginTop: "20px", marginBottom: "20px", color: "#dbdbdb" }}
          value={collectionInfo.name}
          onChange={(event) =>
            dispatch(
              setCollectionInfo({
                ...collectionInfo,
                name: event.target.value,
              })
            )
          }
        />
        <TextField
          focused
          className="InputForm"
          multiline
          minRows={2}
          label="Description"
          variant="outlined"
          sx={{ marginBottom: "20px" }}
          value={collectionInfo.description}
          onChange={(event) =>
            dispatch(
              setCollectionInfo({
                ...collectionInfo,
                description: event.target.value,
              })
            )
          }
        />
        <TextField
          focused
          className="InputForm"
          label="Collection Size"
          variant="outlined"
          type={"number"}
          sx={{ marginBottom: "40px" }}
          value={collectionInfo.size}
          onChange={(event) =>
            dispatch(
              setCollectionInfo({
                ...collectionInfo,
                size: event.target.value,
              })
            )
          }
        />
        
        <Typography
          component="div"
          color="primary"
          sx={{
            width: "300px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          Image Sizes
        </Typography>
        <div className="GeneratorMidCheckInfo">
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                disabled={true}
                checked={collectionInfo.imageOpensea}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      imageOpensea: event.target.checked,
                    })
                  )
                }
              />
            }
            label="OpenSea"
          />
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.imageThumb}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      imageThumb: event.target.checked,
                    })
                  )
                }
              />
            }
            label="Thumbnail"
          />
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.imageWeb}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      imageWeb: event.target.checked,
                    })
                  )
                }
              />
            }
            label="Web"
          />
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.imageOg}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      imageOg: event.target.checked,
                    })
                  )
                }
              />
            }
            label="Original"
          />
        </div>
        <Typography
          component="div"
          color="primary"
          sx={{
            width: "300px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          Image Sizes
        </Typography>
        <div className="GeneratorMidCheckInfo">
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.motionWebM}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      motionWebM: event.target.checked,
                    })
                  )
                }
              />
            }
            label="WebM"
          />
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.motionMp4}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      motionMp4: event.target.checked,
                    })
                  )
                }
              />
            }
            label="MP4"
          />
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": {
                fontSize: 16,
                color: "#dbdbdb",
              },
            }}
            control={
              <CustomCheckbox
                checked={collectionInfo.motionGif}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      motionGif: event.target.checked,
                    })
                  )
                }
              />
            }
            label="GIF"
          />
        </div>
      </div>
      <Divider flexItem />
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
          <CancelButton />
          <SaveButton />
        </div>
        <div className="GeneratorBottomInner">
          <NextButton
            disabled={
              collectionInfo.name === "" || collectionInfo.description === ""
            }
            navigateNext={"/generator/layers/add"}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneratorStart;
