import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "../generator.css";
import {
  auth,
} from "../../../components/firebase/firebase";

import CancelButton from "../components/CancelButton";
import NextButton from "../components/NextButton";
import PreviousButton from "../components/PreviousButton";
import StepperNav from "../components/StepperNav";

import { setCollectionInfo } from "../../../components/redux/reduxSlice";

const CustomCheckbox = styled(Checkbox)({
  color: "#dbdbdb",
  flex: 1,
  "&.Mui-checked": {
    color: "#dbdbdb",
  },
});

function GeneratorContract(props) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();
const layersState = useSelector((state) => state.redux.layers);

  const checkLayers = () => {
    if (layersState.length > 0) {
      for (let i = 0; i < layersState.length; i++) {
        if (layersState[i].images.length < 1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (checkLayers() === true) return navigate('/generator/layers/edit')
  }, [user, loading, layersState]);

  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={4}/>
      </div>
      <div className="GeneratorMid">
        <div className="GeneratorTopCancel">
          <CancelButton />
        </div>
        <div className="GeneratorMidCheck">
          <FormControlLabel
            sx={{
              color: "#dbdbdb",
              "& .MuiFormControlLabel-label": { fontSize: 20 },
            }}
            control={
              <CustomCheckbox
                size="large"
                checked={collectionInfo.smartContract}
                onChange={(event) =>
                  dispatch(
              setCollectionInfo({
                ...collectionInfo,
                smartContract: event.target.checked,
              })
            )
                }
              />
            }
            label="Smart Contract"
          />
          <TextField
          focused
            className="InputSelect"
            label="Blockchain"
            variant="outlined"
            disabled={collectionInfo.smartContract === false}
            select
            value={collectionInfo.blockchain}
            onChange={(event) =>
              dispatch(
              setCollectionInfo({
                ...collectionInfo,
                blockchain: event.target.value,
              })
            )
            }
          >
            <MenuItem value={"ethereum"}>Ethereum</MenuItem>
            <MenuItem value={"ethereumTest"}>Ethereum - Rinkeby Testnet</MenuItem>
            <MenuItem value={"polygon"}>Polygon</MenuItem>
            <MenuItem value={"polygonTest"}>Polygon - Testnet</MenuItem>
            <MenuItem value={"bsc"}>BSC</MenuItem>
            <MenuItem value={"bscTest"}>BSC - Testnet</MenuItem>
          </TextField>
          
        </div>
      </div>
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
            <PreviousButton
            navigateNext={"/generator/upload"}
          />
        </div>
        <div className="GeneratorBottomInner">
          <NextButton
            disabled={checkLayers()}
            navigateNext={"/generator/generate"}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneratorContract;
