import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import "./html.css";
import { auth } from "./../../components/firebase/firebase";

var JSZip = require("jszip");

const CustomCheckbox = styled(Checkbox)({
  color: "#dbdbdb",
  flex: 1,
  "&.Mui-checked": {
    color: "#dbdbdb",
  },
});

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  height: "120px",
  fontSize: "80%",
  textAlign: "center",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

let htmlObj = `<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=windows-1252" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      rel="stylesheet"
    />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <link
      rel="stylesheet"
      href="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/style.css"
    />
    <meta name="description" content="The Scandos ÄNN EFF THÉ" />
    <meta property="og:title" content="The Scandos" />
    <meta property="og:description" content="The Scandos ÄNN EFF THÉ" />
    <meta
      property="og:image"
      content="https://firebasestorage.googleapis.com/v0/b/thescandos.appspot.com/o/logoSocial.png?alt=media"
    />
    <meta property="og:url" content="https://thescandos.xyz" />
    <meta property="og:type" content="website" />
    <meta property="twitter:title" content="The Scandos" />
    <meta property="twitter:description" content="The Scandos ÄNN EFF THÉ" />
    <meta
      property="twitter:image"
      content="https://firebasestorage.googleapis.com/v0/b/thescandos.appspot.com/o/logoSocial.png?alt=media"
    />
  </head>
  <body cz-shortcut-listen="true">
    <div class="wrap">
      <img
        class="main"
        src="https://nftstorage.link/ipfs/bafybeihk3jbqoolkqeaiwt27ahranpi5esqep2ydsbowwpr7zvbfdwukaa/2000.png"
      />

      <div class="slider">
        <div class="sliderHeader">
          <img
            class="headerLogo"
            src="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/header.png"
          />
          <div class="linkButtonsDiv">
            <a
              href="https://twitter.com/thescandos"
              class="linkButton"
              target="_blank"
            ></a>
            <a
              href="https://thescandos.xyz"
              class="linkButton"
              target="_blank"
            ></a>
          </div>
          <div class="menuButtonsDiv">
            <div onclick="togglePage('about')" class="menuButtonAbout"></div>
            <div
              onclick="togglePage('roadmap')"
              class="menuButtonRoadmap"
            ></div>
            <div onclick="togglePage('mint')" class="menuButtonMint"></div>
          </div>
        </div>
        <div class="sliderInfo">
          <img
            class="infoImg"
            src="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/info.jpeg"
          />
        </div>
        <div class="sliderRoadmap">
          <lottie-player
            class="roadmapAnimation"
            src="https://nftstorage.link/ipfs/bafkreifosxsf5bnl7k2jc3s2vaasaijugln6ehagm2ybd5ab72cnmnzqiy"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div class="sliderMint">
          <img
            class="mintImg"
            src="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/mint1.png"
          />
          <img
            class="mintImg"
            src="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/mint2.png"
          />
          <img
            class="mintImg"
            src="https://nftstorage.link/ipfs/bafybeifndj6q6o6im6nns2fifrojuf7dl57o73vhkjgrfugis2lq6qde3a/mint3.png"
          />
          <div class="mintDiv">
            <button id="connectButton" class="connectButton" onclick="">
              Connect Wallet
            </button>
            <div id="mintPrice" class="mintPrice"></div>
            <div class="mintInputDiv">
              <button
                id="mintInputButtonsMinus"
                class="mintInputButtons"
                onclick=""
              >
                -
              </button>
              <div id="mintInput" class="mintInput"></div>
              <button
                id="mintInputButtonsPlus"
                class="mintInputButtons"
                onclick=""
              >
                +
              </button>
            </div>
            <button class="mintButton">Mint NFT</button>
            <p id="mintText" class="mintText">
              Connect Wallet and input number of NFTs to be minted.
            </p>
          </div>
        </div>
      </div>
      <div class="menu" onclick="toggle()">
        <svg class="icon" width="7.26" height="28" fill="white">
          <path
            d="M10,20.74a3.63,3.63,0,1,1-3.63,3.63A3.64,3.64,0,0,1,10,20.74Zm0-10.37A3.63,3.63,0,1,1,6.37,14,3.64,3.64,0,0,1,10,10.37Zm0-3.11a3.63,3.63,0,1,1,3.63-3.63A3.64,3.64,0,0,1,10,7.26Z"
            transform="translate(-6.37)"
            style="fill: #fff"
          />
        </svg>
      </div>
    </div>
    <script>
      id = 200;
      function toggle() {
        if (status != "open") {
          $("body").addClass("open");
          status = "open";
        } else {
          $("body").removeClass("open");
          status = "closed";
        }
      }
      function togglePage(toggle) {
        if (toggle == "roadmap") {
          $("body").removeClass("versionsOpen");
          $("body").removeClass("aboutOpen");
          $("body").removeClass("mintOpen");
          $("body").addClass("roadmapOpen");
        } else if (toggle == "mint") {
          $("body").removeClass("versionsOpen");
          $("body").removeClass("aboutOpen");
          $("body").removeClass("roadmapOpen");
          $("body").addClass("mintOpen");
        } else {
          $("body").removeClass("versionsOpen");
          $("body").removeClass("roadmapOpen");
          $("body").removeClass("mintOpen");
          $("body").addClass("aboutOpen");
        }
      }
    </script>
    <script>
      console.log(window);
      window.userWalletAddress = null;
      const connectButton = document.getElementById("connectButton");
      const mintText = document.getElementById("mintText");

      function toggleButton() {
        console.log(window.ethereum);
        if (!window.ethereum) {
          connectButton.innerText = "No Wallet";
          mintText.innerText =
            "You need to have Metamask installed to be able to mint!";
          $("body").addClass("walletMissing");
          $("body").removeClass("connected");
          $("body").removeClass("disconnected");
          return false;
        }

        connectButton.addEventListener("click", loginWithMetaMask);
      }

      async function loginWithMetaMask() {
        const accounts = await window.ethereum
          .request({ method: "eth_requestAccounts" })
          .catch((e) => {
            console.error(e.message);
            return;
          });
        if (!accounts) {
          return;
        }

        window.userWalletAddress = accounts[0];
        mintText.innerText = "Input number of NFTs to be minted.";
        connectButton.innerText = "Disconnect Wallet";
        $("body").addClass("connected");
        $("body").removeClass("disconnected");
        $("body").removeClass("walletMissing");

        connectButton.removeEventListener("click", loginWithMetaMask);
        setTimeout(() => {
          connectButton.addEventListener("click", signOutOfMetaMask);
        }, 200);
      }

      function signOutOfMetaMask() {
        window.userWalletAddress = null;
        mintText.innerText =
          "Connect Wallet and input number of NFTs to be minted.";
        connectButton.innerText = "Connect Wallet";
        $("body").addClass("disconnected");
        $("body").removeClass("connected");
        $("body").removeClass("walletMissing");

        connectButton.removeEventListener("click", signOutOfMetaMask);
        setTimeout(() => {
          connectButton.addEventListener("click", loginWithMetaMask);
        }, 200);
      }

      window.addEventListener("DOMContentLoaded", () => {
        toggleButton();
      });
    </script>
    <script>
      window.mintCount = 1;
      window.mintPrice = 0;
      const mintInput = document.getElementById("mintInput");
      const mintPrice = document.getElementById("mintPrice");

      const mintInputButtonsMinus = document.getElementById(
        "mintInputButtonsMinus"
      );
      const mintInputButtonsPlus = document.getElementById(
        "mintInputButtonsPlus"
      );

      function calculatePrice() {
        if (window.mintCount > 5) {
          let tempCount = window.mintCount - 5;
          window.mintPrice = tempCount * 0.02;
        }

        if (window.mintCount < 1) {
          window.mintCount = 1;
          window.mintPrice = 0;
        }
        mintInput.innerText = window.mintCount;
        mintPrice.innerText = Number(window.mintPrice).toFixed(2);
        mintInputButtonsMinus.addEventListener("click", mintButtonMinus);
        mintInputButtonsPlus.addEventListener("click", mintButtonPlus);
      }

      function mintButtonMinus() {
        window.mintCount = window.mintCount - 1;
        if (window.mintCount > 5) {
          let tempCount = window.mintCount - 5;
          window.mintPrice = tempCount * 0.02;
        }

        if (window.mintCount < 6) {
          window.mintPrice = 0;
        }

        if (window.mintCount < 1) {
          window.mintCount = 1;
          window.mintPrice = 0;
        }
        mintInput.innerText = window.mintCount;
        mintPrice.innerText = Number(window.mintPrice).toFixed(2);
      }

      function mintButtonPlus() {
        window.mintCount = window.mintCount + 1;
        if (window.mintCount > 5) {
          let tempCount = window.mintCount - 5;
          window.mintPrice = tempCount * 0.02;
        }

        if (window.mintCount < 6) {
          window.mintPrice = 0;
        }

        if (window.mintCount < 1) {
          window.mintCount = 1;
          window.mintPrice = 0;
        }
        mintInput.innerText = window.mintCount;
        mintPrice.innerText = Number(window.mintPrice).toFixed(2);
      }

      window.addEventListener("DOMContentLoaded", () => {
        calculatePrice();
      });
    </script>
  </body>
</html>
`;

function HtmlGeneratorStart() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jsonData, setJsonData] = useState([]);
  const [ipfsData, setIpfsData] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [hiddenData, setHiddenData] = useState("");
  const [hiddenStart, setHiddenStart] = useState(0);
  const [generating, setGenerating] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const fileReader = new FileReader();
    fileReader.readAsText(acceptedFiles[0], "UTF-8");
    fileReader.onload = (e) => {
      let parsedData = JSON.parse(e.target.result);
      console.log(parsedData);
      setJsonData(parsedData);
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".json" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  const handleClick = async () => {
    setGenerating(true);
    let zip = new JSZip();

    let metadataArr = []
    for (let i = 0; i < jsonData.length; i++) {
      let tempObject = jsonData[i];

      let newMetadataObj;

      newMetadataObj = {
          name: tempObject.name,
          description: tempObject.description,
          image: ipfsData + "" + i + ".png",
          attributes: tempObject.attributes,
        };

        metadataArr.push(newMetadataObj)

        let data = new Blob([htmlObj], {type: 'text/html'});
      zip.file(
        `html/${i}.html`,
        data
      );

      if (jsonData.length - 1 === i) {
        let data = JSON.stringify(metadataArr, null, 2);
        zip.file("metadata.json", data);
        zip.generateAsync({ type: "blob" }).then(function (content) {
          setGenerating(false);
          saveAs(content, `html.zip`);
        });
      }
    }
  };

  console.log(ipfsData)
  console.log(hiddenData)
  console.log(hiddenStart)
  console.log(isHidden)

  return (
    <div className="HtmlGeneratorInner">
      <Box sx={{ width: "70%" }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop Metadata JSON-file here!</p>
        </div>
      </Box>
      <TextField
        sx={{ width: "70%", marginTop: "30px", marginBottom: "15px" }}
        fullWidth
        onChange={(event) => setIpfsData(event.target.value)}
        id="outlined-basic"
        label="IPFS Image Address"
        variant="outlined"
        value={ipfsData}
      />
      <Button
        variant="text"
        sx={{ color: "#fff" }}
        onClick={handleClick}
        disabled={ipfsData === ""}
      >
        Generate HTML Files
      </Button>
    </div>
  );
}

export default HtmlGeneratorStart;
