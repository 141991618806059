import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "../generator.css";
import { auth } from "../../../components/firebase/firebase";

import CancelButton from "../components/CancelButton";
import NextButton from "../components/NextButton";
import PreviousButton from "../components/PreviousButton";
import StepperNav from "../components/StepperNav";
import SaveButton from "../components/SaveButton";

import { setCollectionInfo } from "../../../components/redux/reduxSlice";

const CustomCheckbox = styled(Checkbox)({
  color: "#dbdbdb",
  flex: 1,
  "&.Mui-checked": {
    color: "#dbdbdb",
  },
});

function GeneratorIpfs(props) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();
  const layersState = useSelector((state) => state.redux.layers);

  const checkLayers = () => {
    if (layersState.length > 0) {
      for (let i = 0; i < layersState.length; i++) {
        if (layersState[i].images.length < 1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (checkLayers() === true) return navigate("/generator/layers/edit");
  }, [user, loading, layersState]);

  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={3} />
      </div>
      <div className="GeneratorMid">
        <div className="GeneratorMidCheck">
          <div className="GeneratorMidCheckInner">
            <FormControlLabel
              sx={{
                color: "#dbdbdb",
                "& .MuiFormControlLabel-label": { fontSize: 20 },
              }}
              control={
                <CustomCheckbox
                  size="large"
                  checked={collectionInfo.ipfs}
                  onChange={(event) =>
                    dispatch(
                      setCollectionInfo({
                        ...collectionInfo,
                        ipfs: event.target.checked,
                      })
                    )
                  }
                />
              }
              label="IPFS"
            />
            <div className="InputSelectOuter">
              <TextField
              fullWidth
                focused
                className="InputSelect"
                label="IPFS Provider"
                variant="outlined"
                disabled={collectionInfo.ipfs === false}
                select
                value={collectionInfo.ipfsProvider}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      ipfsProvider: event.target.value,
                    })
                  )
                }
              >
                <MenuItem value={"nft.storage"}>NFT.storage</MenuItem>
                <MenuItem value={"pinata"}>Pinata</MenuItem>
              </TextField>
            </div>

            {collectionInfo.ipfsProvider === "" ||
            collectionInfo.ipfs === false ? null : (
              <TextField
                focused
                fullWidth
                label="Provider Key"
                variant="outlined"
                sx={{ marginTop: "20px", marginBottom: "20px" }}
                value={collectionInfo.ipfsProviderKey}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      ipfsProviderKey: event.target.value,
                    })
                  )
                }
              />
            )}
          </div>
          <div className="GeneratorMidCheckInner">
            <FormControlLabel
              sx={{
                color: "#dbdbdb",
                "& .MuiFormControlLabel-label": { fontSize: 20 },
              }}
              control={
                <CustomCheckbox
                  size="large"
                  checked={collectionInfo.cloud}
                  onChange={(event) =>
                    dispatch(
                      setCollectionInfo({
                        ...collectionInfo,
                        cloud: event.target.checked,
                      })
                    )
                  }
                />
              }
              label="Cloud"
            />
            <div className="InputSelectOuter">
              <TextField
              fullWidth
                focused
                className="InputSelect"
                label="Cloud Provider"
                variant="outlined"
                disabled={collectionInfo.cloud === false}
                select
                value={collectionInfo.cloudProvider}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      cloudProvider: event.target.value,
                    })
                  )
                }
              >
                <MenuItem value={"aws"}>AWS</MenuItem>
                <MenuItem value={"firebase"}>Firebase</MenuItem>
              </TextField>
            </div>

            {collectionInfo.cloudProvider === "" ||
            collectionInfo.cloud === false ? null : (
              <TextField
                focused
                fullWidth
                label="Provider Key"
                variant="outlined"
                sx={{ marginTop: "20px", marginBottom: "20px" }}
                value={collectionInfo.cloudProviderKey}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      cloudProviderKey: event.target.value,
                    })
                  )
                }
              />
            )}
          </div>
          <div className="GeneratorMidCheckInner">
            <FormControlLabel
              sx={{
                color: "#dbdbdb",
                "& .MuiFormControlLabel-label": { fontSize: 20 },
              }}
              control={
                <CustomCheckbox
                  size="large"
                  checked={collectionInfo.smartContract}
                  onChange={(event) =>
                    dispatch(
                      setCollectionInfo({
                        ...collectionInfo,
                        smartContract: event.target.checked,
                      })
                    )
                  }
                />
              }
              label="Smart Contract"
            />
            <div className="InputSelectOuter">
              <TextField
              fullWidth
                focused
                className="InputSelect"
                label="Blockchain"
                variant="outlined"
                disabled={collectionInfo.smartContract === false}
                select
                value={collectionInfo.blockchain}
                onChange={(event) =>
                  dispatch(
                    setCollectionInfo({
                      ...collectionInfo,
                      blockchain: event.target.value,
                    })
                  )
                }
              >
                <MenuItem value={"ethereum"}>Ethereum</MenuItem>
                <MenuItem value={"ethereumTest"}>
                  Ethereum - Rinkeby Testnet
                </MenuItem>
                <MenuItem value={"polygon"}>Polygon</MenuItem>
                <MenuItem value={"polygonTest"}>Polygon - Testnet</MenuItem>
                <MenuItem value={"bsc"}>BSC</MenuItem>
                <MenuItem value={"bscTest"}>BSC - Testnet</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
      </div>
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
          <CancelButton />
          <SaveButton/>
        </div>
        <div className="GeneratorBottomInner">
          <PreviousButton navigateNext={"/generator/layers/edit"} />
          <NextButton
            disabled={checkLayers()}
            navigateNext={"/generator/generate"}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneratorIpfs;
