import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button, { ButtonProps } from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../generator.css";
import { auth } from "../../../components/firebase/firebase";

import CancelButton from "../components/CancelButton";
import SaveButton from "../components/SaveButton";
import StepperNav from "../components/StepperNav";
import PreviousButton from "../components/PreviousButton";
import {
  initGenerator,
  mixGeneratedArray,
} from "../../../components/generate/randomize";
import { imageGenerator } from "../../../components/generate/generateImage";
import {
  generateZip,
  generateMetadata,
} from "../../../components/generate/generateZip";

import {
  setCollectionInfo,
  setLoading,
  setLoadingImages,
  resetLoadingImages
} from "../../../components/redux/reduxSlice";

function InitGenerate(props) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const generateState = useSelector((state) => state.redux.generateState);
  const loadingImages = useSelector((state) => state.redux.loadingImages);
  const [imageData, setImageData] = useState("");

  const layers = useSelector((state) => state.redux.layers);
  const dispatch = useDispatch();

  const checkLayers = () => {
    if (layers.length > 0) {
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].images.length < 1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };
  const startGenerate = async () => {
    dispatch(
      setLoading({
        ...generateState,
        loading: true,
        loadingMessage: "Initializing Generator...",
      })
    );
    const generatedArr = await initGenerator(
      layers,
      collectionInfo,
      generateState
    );
    const mixedArr = await mixGeneratedArray(generatedArr);
    const mixedArr2 = await mixGeneratedArray(mixedArr);
    const mixedArr3 = await mixGeneratedArray(mixedArr2);

    if (collectionInfo.metadata === true) {
      await generateMetadata(mixedArr3, collectionInfo);
    }
    

    let spliceArr = [];

    while (mixedArr3.length) {
      spliceArr.push(mixedArr3.splice(0, generateState.zipImages));
    }
    for (let i = 0; i < spliceArr.length; i++) {
      await generateZip(
        spliceArr[i],
        collectionInfo,
        generateState,
        i,
        spliceArr.length
      );
    }
    dispatch(
      setLoading({
        ...generateState,
        loading: false,
        loadingZip: false,
      })
    );
    dispatch(
      resetLoadingImages()
    );
    navigate("/");
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (checkLayers() === true) return navigate("/generator/layers/edit");
  }, [user, loading, layers]);

  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={4} />
      </div>
      <div className="GeneratorMid">
        {loadingImages.length >= 12 ? (
          <div className="GeneratorImageOuter">
            <div className="GeneratorImageInner">
              <img
                className="GeneratorImage"
                src={loadingImages[6]}
                srcSet={loadingImages[6]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[7]}
                srcSet={loadingImages[7]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[8]}
                srcSet={loadingImages[8]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[9]}
                srcSet={loadingImages[9]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[10]}
                srcSet={loadingImages[10]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[11]}
                srcSet={loadingImages[11]}
                alt="Generated NFT"
              />
            </div>
            <div className="GeneratorImageOver"></div>
          </div>
        ) : null}
        {generateState.loading === false ? (
          <div className="GeneratorMidInit">
            <TextField
              focused
              className="InputForm"
              label="Images per Zip file"
              variant="outlined"
              type={"number"}
              sx={{ marginBottom: "40px", maxWidth: "220px" }}
              value={generateState.zipImages}
              onChange={(event) =>
                dispatch(
                  setLoading({
                    ...generateState,
                    zipImages: event.target.value,
                  })
                )
              }
            />
            <Button
              onClick={startGenerate}
              disabled={checkLayers()}
              variant="contained"
            >
              Start
            </Button>
          </div>
        ) : generateState.loadingZip === true ? (
          <div className="GeneratorMidInit">
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress size={80} />
            </Box>
            <Typography
              component="div"
              color="primary"
              sx={{ width: "300px", textAlign: "center", marginTop: "20px" }}
            >
              {generateState.loadingMessage}
            </Typography>
          </div>
        ) : (
          <div className="GeneratorMidInit">
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              {imageData.length >= 12 ? (
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    component="div"
                    color="primary"
                    sx={{
                      width: "300px",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {`${Math.round(generateState.loadingProgress)}%`}
                  </Typography>
                </Box>
              ) : null}
              <CircularProgress
                variant="determinate"
                value={Number(generateState.loadingProgress)}
                size={80}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography component="div" color="text.secondary">
                  {`${Math.round(generateState.loadingProgress)}%`}
                </Typography>
              </Box>
            </Box>
            <Typography
              component="div"
              color="primary"
              sx={{ width: "300px", textAlign: "center", marginTop: "20px" }}
            >
              {generateState.loadingMessage}
            </Typography>
          </div>
        )}
        {loadingImages.length >= 12 ? (
          <div className="GeneratorImageOuter">
            <div className="GeneratorImageInner">
              <img
                className="GeneratorImage"
                src={loadingImages[5]}
                srcSet={loadingImages[5]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[4]}
                srcSet={loadingImages[4]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[3]}
                srcSet={loadingImages[3]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[2]}
                srcSet={loadingImages[2]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[1]}
                srcSet={loadingImages[1]}
                alt="Generated NFT"
              />
              <img
                className="GeneratorImage"
                src={loadingImages[0]}
                srcSet={loadingImages[0]}
                alt="Generated NFT"
              />
            </div>
            <div className="GeneratorImageOver"></div>
          </div>
        ) : null}
      </div>
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
          <CancelButton />
          <SaveButton />
        </div>
        <div className="GeneratorBottomInner">
          <PreviousButton navigateNext={"/generator/upload"} />
        </div>
      </div>
    </div>
  );
}

export default InitGenerate;
