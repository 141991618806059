import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";

import "../pgpTools.css";
import { auth } from "../../../components/firebase/firebase";

const axios = require("axios");

function PgpToolsStart() {
  const [user, loading, error] = useAuthState(auth);
  const [keyPair, setKeyPair] = useState(null);
  const navigate = useNavigate();

  const generetateKeys = () => {
    axios
      .get("https://dev.jpg.studio/api/pgp/create")
      .then(function (response) {
        // handle success
        console.log(response);
        setKeyPair(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="PgpToolsInner">
      <button className="StartButton" onClick={() => generetateKeys()}>
        Generate Public/Private Key Pair
      </button>
      {keyPair ? (
        <div>
          <p>Public Key: {keyPair.signerAddress}</p>
          <p>Private Key: {keyPair.pvtKeyString}</p>
        </div>
      ) : null}
    </div>
  );
}

export default PgpToolsStart;
