import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { animated } from "@react-spring/web";
import IconButton from "@mui/material/IconButton";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { useDropzone } from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import "../generator.css";

import {
  removeLayer,
  addImages,
  updateImageAmount,
} from "../../../components/redux/reduxSlice";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  height: "100px",
  fontSize: "90%",
  textAlign: "center",
};

const activeStyle = {
  backgroundColor: "#67a8c6",
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#00e676",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImageDrop(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [layer, setLayer] = useState(props.layer);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const [layerImages, setLayerImages] = useState(props.layer.images);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    let imageArr = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      console.log(acceptedFiles[i]);
      let tempFile = {
        url: URL.createObjectURL(acceptedFiles[i]),
        name: acceptedFiles[i].name,
        count: 0,
      };
      imageArr.push(tempFile);
    }
    const keyIndex = layer.listIndex;
    dispatch(
      updateImageAmount({
        ...props.layer,
        imageAmount: 0,
      })
    );
    dispatch(addImages({ keyIndex, imageArr }));
    
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/*" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (layer.current) {
      setLayer(layer.current);
    }
  }, [layer]);

  useEffect(() => {
    if (layerImages.current) {
      setLayerImages(layerImages.current);
    }
  }, [layerImages]);

  const handleDelete = () => {
    const listIndex = layer.listIndex;
    dispatch(addImages({ listIndex, imageArr: [] }));
  };

  return (
    <div className="ImageDropOuter">
      <div style={{ width: "90%" }} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className="ImageDropButtons">
        <IconButton
          aria-label="delete"
          onClick={handleClickOpen}
          disabled={props.images.length < 1}
        >
          <PreviewIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={handleDelete}
          disabled={props.images.length < 1}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#369ac9",
          },
        }}
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogContent>
          <div className="DialogContent">
            {props.images.length > 0 ? (
              <ImageList
                sx={{ width: 500, height: 450 }}
                cols={3}
                rowHeight={164}
              >
                {props.images.map((item, i) => (
                  <ImageListItem key={i}>
                    <img
                      src={item.url}
                      srcSet={item.url}
                      alt="Layer"
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <p>Add images to layer to be able to view them here</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImageDrop;
