import * as React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import "../generator.css";

const ColorButton = styled(Button)(() => ({
  color: '#369ac9',
  margin: '20px',
  backgroundColor: '#dbdbdb',
  '&:hover': {
    backgroundColor: '#bcbbbb',
  },
}));

export default function PreviousButton(props) {
  const navigate = useNavigate();
  return (
    <ColorButton disabled={props.disabled} onClick={() => navigate(props.navigateNext)} variant="contained">
      Previous
    </ColorButton>
  );
}
