import React from "react";
import mergeImages from "merge-images";
import { saveAs } from "file-saver";
import Resizer from "react-image-file-resizer";
import { store } from "../redux/store";
import {
  setLoading,
  setLoadingImages,
  resetLoadingImages,
} from "../redux/reduxSlice";

const { Canvas, Image } = require("canvas");
var JSZip = require("jszip");

export const imageGenerator = async (
  mixedArr,
  collectionInfo,
  generateStatePassed,
  navigate
) => {
  let state = store.getState();
  let zip = new JSZip();

  let metadataArr = [];

  let count = 0;
  let zipImagesCount = 0;

  for (let i = 0; i < mixedArr.length; i++) {
    let stringCount = count.toString();

    if (count % 2 === 0) {
      store.dispatch(
        setLoading({
          ...state.generateState,
          loadingMessage: "Generating image #" + count,
          loadingProgress: (100 / mixedArr.length) * count,
        })
      );
    }

    /*if (count % 30 === 0) {
      let arrCopy = [...]
      store.dispatch(
        setLoadingImages()
      );
    }*/

    let tempTraits = [];
    let tempUrls = [];
    for (let j = 0; j < mixedArr[i].length; j++) {
      let tempObj = {
        trait_type: mixedArr[i][j].trait_type,
        value: mixedArr[i][j].value,
      };
      tempTraits.push(tempObj);
      tempUrls.push(mixedArr[i][j].url);
    }

    let metadataObj = {
      name: collectionInfo.name + " #" + count,
      description: collectionInfo.description,
      image: "",
      fileName: stringCount,
      attributes: tempTraits,
    };
    console.log("Image Count: " + stringCount);
    console.log(metadataObj);
    try {
      const generatedImage = await mergeImages(tempUrls, {
        Canvas: Canvas,
        Image: Image,
      });
      const base64Response = await fetch(generatedImage);
      const blob = await base64Response.blob();
      const generatedOpensea = await resizeOpenSea(blob);

      if (count < 12) {
        store.dispatch(setLoadingImages(generatedOpensea));
      }
      if (count % 8 === 0) {
        store.dispatch(setLoadingImages(generatedOpensea));
      }
      metadataArr.push(metadataObj);

      if (collectionInfo.imageThumb) {
        const generatedThumbnail = await resizeThumbnail(blob);
        zip.file(
          `thumbnail/${stringCount}.png`,
          generatedThumbnail.split(",").pop(),
          { base64: true }
        );
      }
      if (collectionInfo.imageWeb) {
        const generatedWeb = await resizeWeb(blob);
        zip.file(`web/${stringCount}.png`, generatedWeb.split(",").pop(), {
          base64: true,
        });
      }
      if (collectionInfo.imageOg) {
        const generatedRaw = await resizeRaw(blob);
        zip.file(`original/${stringCount}.png`, generatedRaw.split(",").pop(), {
          base64: true,
        });
      }
      zip.file(
        `opensea/${stringCount}.png`,
        generatedOpensea.split(",").pop(),
        { base64: true }
      );

      count = count + 1;
      zipImagesCount = zipImagesCount + 1;

      if (
        Number(zipImagesCount) % Number(generateStatePassed.zipImages) ===
        0
      ) {
        store.dispatch(
          setLoading({
            ...state.generateState,
            loadingMessage:
              "Generating zip-file #" +
              Math.floor(count / generateStatePassed.zipImages),
          })
        );
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${collectionInfo.name}.zip`);
        });
        zip = new JSZip();
      }

      if (mixedArr.length === count) {
        store.dispatch(resetLoadingImages());
        store.dispatch(
          setLoading({
            ...state.generateState,
            loadingMessage:
              "Generating zip-file #" +
              Math.floor(count / generateStatePassed.zipImages),
          })
        );
        if (collectionInfo.metadata === true) {
          let data = JSON.stringify(metadataArr, null, 2);
          zip.file("metadata.json", data);
        }

        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${collectionInfo.name}.zip`);
          store.dispatch(
            setLoading({
              ...state.generateState,
              loading: false,
              loadingZip: false,
            })
          );
          navigate("/");
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
};

const resizeRaw = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      2000,
      2000,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const resizeThumbnail = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      256,
      256,
      "PNG",
      25,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const resizeWeb = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "PNG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const resizeOpenSea = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      "PNG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
