import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";

import "./experimental.css";
import { auth } from "../../components/firebase/firebase";

import ExperimentalStart from "./views/ExperimentalStart";

function Experimental() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="Experimental">
      <Routes>
        <Route path="/" element={<ExperimentalStart />} />
      </Routes>
    </div>
  );
}

export default Experimental;
