import React from "react";
import mergeImages from "merge-images";
import { saveAs } from "file-saver";
import Resizer from "react-image-file-resizer";
import { store } from "../redux/store";
import {
  setLoading,
  setLoadingImages,
  resetLoadingImages,
} from "../redux/reduxSlice";
import { width } from "@mui/system";

const { Canvas, Image, createCanvas, loadImage } = require("canvas");
var JSZip = require("jszip");

export const generateZip = async (
  mixedArr,
  collectionInfo,
  generateStatePassed,
  zipIndex,
  zipIndexLength
) => {
  let zip = new JSZip();

  let count = 0;

  for (let i = 0; i < mixedArr.length; i++) {
    let stringCount = (
      count +
      Number(generateStatePassed.zipImages) * zipIndex
    ).toString();

    if (count % 2 === 0) {
      store.dispatch(
        setLoading({
          ...generateStatePassed,
          loadingMessage:
            "Generating image #" +
            (count + Number(generateStatePassed.zipImages) * zipIndex),
          loadingProgress:
            (100 / Number(collectionInfo.size)) *
            (count + Number(generateStatePassed.zipImages) * zipIndex),
          loading: true,
        })
      );
    }

    let tempTraits = [];
    let tempUrls = [];
    for (let j = 0; j < mixedArr[i].length; j++) {
      let tempObj = {
        trait_type: mixedArr[i][j].trait_type,
        value: mixedArr[i][j].value,
      };
      tempTraits.push(tempObj);
      tempUrls.push(mixedArr[i][j].url);
    }

    /*const canvas = createCanvas(1080, 1080)
    const ctx = canvas.getContext('2d')
    let x = 870
    let y = 890
    ctx.fillStyle = '#fff';
    ctx.fillRect(x, y, 110, 100);
    ctx.fillStyle = '#000';
    ctx.font = '35px Poppins ExtraLight'
    let message = stringCount.toString()
    let textWidth = ctx.measureText(message).width;
    ctx.fillText(message, 923 - (textWidth/2), 949)

    tempUrls.push(canvas.toDataURL())*/
    try {
      const generatedImage = await mergeImages(tempUrls, {
        Canvas: Canvas,
        Image: Image,
      });

      const base64Response = await fetch(generatedImage);
      const blob = await base64Response.blob();
      const generatedOpensea = await resizeImage(blob, 400, 400, 80);

      if (count + Number(generateStatePassed.zipImages) * zipIndex < 12) {
        store.dispatch(setLoadingImages(generatedOpensea));
      }
      if (
        (count + Number(generateStatePassed.zipImages) * zipIndex) % 5 ===
        0
      ) {
        store.dispatch(setLoadingImages(generatedOpensea));
      }

      /*if (collectionInfo.imageThumb) {
        const generatedThumbnail = await resizeImage(blob, 256, 256, 25);
        zip.file(
          `thumbnail/${stringCount}.png`,
          generatedThumbnail.split(",").pop(),
          { base64: true }
        );
      }
      if (collectionInfo.imageWeb) {
        const generatedWeb = await resizeImage(blob, 1024, 1024, 80);
        zip.file(`web/${stringCount}.png`, generatedWeb.split(",").pop(), {
          base64: true,
        });
      }
      if (collectionInfo.imageOg) {
        const generatedRaw = await resizeImage(blob, 2000, 2000, 100);
        zip.file(`original/${stringCount}.png`, generatedRaw.split(",").pop(), {
          base64: true,
        });
      }
      zip.file(
        `opensea/${stringCount}.png`,
        generatedOpensea.split(",").pop(),
        { base64: true }
      );*/

      
      const generatedRaw = await resizeImage(blob, 1080, 1080, 100);
      zip.file(`images/${stringCount}.png`, base64Response.url.split(",").pop(), {
        base64: true,
      });

      count = count + 1;

      if (mixedArr.length === count) {
        store.dispatch(
          setLoading({
            ...generateStatePassed,
            loadingMessage:
              "Generating zip-file #" +
              (zipIndex + 1) +
              " of " +
              zipIndexLength,
            loadingProgress:
              (100 / Number(collectionInfo.size)) *
              (count + Number(generateStatePassed.zipImages) * zipIndex),
            loading: true,
          })
        );

        let zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(
          zipContent,
          `${collectionInfo.name}-${zipIndex + 1}of${zipIndexLength}.zip`
        );
        return;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const generateMetadata = async (mixedArr, collectionInfo) => {
  let zip = new JSZip();

  let metadataArr = [];

  let count = 0;

  for (let i = 0; i < mixedArr.length; i++) {
    let stringCount = count.toString();

    let tempTraits = [];
    let tempUrls = [];
    for (let j = 0; j < mixedArr[i].length; j++) {
      if (mixedArr[i][j].value !== 'None') {
        let tempObj = {
        trait_type: mixedArr[i][j].trait_type,
        value: mixedArr[i][j].value,
      };
      tempTraits.push(tempObj);
      }
      tempUrls.push(mixedArr[i][j].url);
    }

    let metadataObj = {
      name: collectionInfo.name + " #" + count,
      description: collectionInfo.description,
      image: "",
      //thumbnail: "",
      //webImage: "",
      //originalImage: "",
      //offchainData: {},
      extendedMetadataLink: "",
      attributes: tempTraits,
    };

    try {
      metadataArr.push(metadataObj);

      count = count + 1;

      if (mixedArr.length === count) {
        let data = JSON.stringify(metadataArr, null, 2);
        zip.file("metadata.json", data);

        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${collectionInfo.name}-Metadata.zip`);
          return;
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
};

const resizeImage = (file, max, min, quality) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      min,
      max,
      "PNG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
