import React, { useEffect, useState, useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { animated } from "@react-spring/web";
import IconButton from "@mui/material/IconButton";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  removeLayer,
  updateLayer,
} from "../../../components/redux/reduxSlice";
import "../generator.css";

function LayerComponent(props) {
  const dispatch = useDispatch();
  const [layerImages, setLayerImages] = useState(props.layer.images);
  const layersState = useSelector((state) => state.redux.layers);
  const { zIndex, shadow, y, scale } = props.spring;

  const handleDelete = () => {
    dispatch(removeLayer(props.layer.listIndex));
  };

  return (
    <animated.div
      key={props.layer.keyIndex}
      style={{
        zIndex,
        boxShadow: shadow.to(
          (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
        ),
        y,
        scale,
      }}
      className="LayerComponent"
    >
      <Box sx={{ width: "8%" }}>
        <IconButton aria-label="delete">
          <DragHandleIcon {...props.bind(props.layer.listIndex)} />
        </IconButton>
      </Box>
      <TextField
        focused
        className="InputSelect"
        label="Metadata Name"
        variant="outlined"
        value={props.layer.name}
        size="small"
        onChange={(event) => dispatch(updateLayer({...props.layer, name: event.target.value}))}
      />
      <TextField
        focused
        className="InputSelect"
        label="Temporary Name"
        variant="outlined"
        value={props.layer.tempName}
        size="small"
        onChange={(event) => dispatch(updateLayer({...props.layer, tempName: event.target.value}))}
      />
      <Box
        sx={{
          width: "8%",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "right",
          paddingRight: "10px",
        }}
      >
        <IconButton aria-label="delete" onClick={handleDelete} disabled={false}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </animated.div>
  );
}

export default LayerComponent;
