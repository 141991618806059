import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Start from "./views/start/Start";
import Login from "./views/auth/Login";
import Generator from "./views/generator/Generator";
import Metadata from "./views/metadata/Metadata";
import Experimental from "./views/experimental/Experimental";
import HtmlGenerator from "./views/htmlGenerator/HtmlGenerator";
import PgpTools from "./views/pgpTools/PgpTools";

export default function App() {

  return (
    <BrowserRouter className="App">
      <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/login" element={<Login />} />
          <Route path="/generator/*" element={<Generator />} />
          <Route path="/metadata/*" element={<Metadata />} />
          <Route path="/experimental/*" element={<Experimental />} />
          <Route path="/html/*" element={<HtmlGenerator />} />
          <Route path="/pgp/*" element={<PgpTools/>} />
        </Routes>
    </BrowserRouter>
  );
}
