import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { useNavigate } from "react-router-dom";
import StepLabel from "@mui/material/StepLabel";
import { StepButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function StepperNav(props) {
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);

  const layersState = useSelector((state) => state.redux.layers);

  const navigate = useNavigate();
  const checkLayers = () => {
    if (layersState.length > 0) {
      for (let i = 0; i < layersState.length; i++) {
        if (layersState[i].images.length < 1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const checkLayersCompleted = () => {
    if (layersState.length > 0) {
      for (let i = 0; i < layersState.length; i++) {
        if (layersState[i].images.length < 1) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const steps = [
    {
      name: "Info",
      nav: "/generator",
      active: "info",
      inputValue:
        collectionInfo.name !== "" &&
        collectionInfo.description !== "" &&
        collectionInfo.size > 0,
      disabled: false,
    },
    {
      name: "Add Layers",
      nav: "/generator/layers/add",
      active: "addLayers",
      inputValue: layersState.length > 0,
      disabled:
        collectionInfo.name === "" ||
        collectionInfo.description === "" ||
        collectionInfo.size < 1,
    },
    {
      name: "Edit Layers",
      nav: "/generator/layers/edit",
      active: "editLayers",
      inputValue: checkLayersCompleted(),
      disabled: layersState.length < 1,
    },
    {
      name: "Upload",
      nav: "/generator/upload",
      active: "ipfs",
      inputValue: false,
      disabled: checkLayers(),
    },
    {
      name: "Generate",
      nav: "/generator/generate",
      active: "generate",
      inputValue: false,
      disabled: checkLayers(),
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.activeLayer} alternativeLabel>
        {steps.map((label, i) => (
          <Step key={i} completed={label.inputValue} disabled={label.disabled}>
            <StepButton onClick={() => navigate(label.nav)}>
              {label.name}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
