import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

import { firebaseConfig } from "../../.config/config";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const changePassword = async (user, password) => {
  try {
    await updatePassword(auth.currentUser, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  logInWithEmailAndPassword,
  logout,
  changePassword,
};
