let generatedArr = [];
let mainCount = 0;

export const initGenerator = async (layers, collectionInfo, generateState) => {
  if (layers.length > 0) {
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].propability === true) {
        await generateRange(layers[i], layers);
      }
    }
    while (generatedArr.length < collectionInfo.size) {
      await generateCommon(layers);
    }
    console.log(generatedArr);
    return generatedArr;
  }
};



const generateCommon = async (layers) => {
  return new Promise((resolve, reject) => {
    let traitArray = [];
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].propability === false) {
        let randomNumber = getRandomInt(layers[i].images.length);
        let tempName = layers[i].images[randomNumber].name.replace(".png", "");
        let tempObj = {
          trait_type: layers[i].name,
          value: tempName,
          url: layers[i].images[randomNumber].url,
        };
        traitArray.push(tempObj);
      }
    }
    generatedArr.push(traitArray);
    return resolve();
  });
};


const generateRange = async (layer, layers) => {
  let imageArr = [];
  for (let i = 0; i < layer.images.length; i++) {
    let imageCopy = { ...layer.images[i] };
    imageArr.push(imageCopy);
  }
  let layerCount = 0;
  let count = 0;
  let traitArray = [];
  let includedLayers = layer.includedLayers;
  let includedLayersCount = 0;
  return new Promise((resolve, reject) => {
    while (count < layer.amount) {
      for (let i = 0; i < layers.length; i++) {
        if (includedLayers.includes(layers[i].tempName)) {
          let randomNumber = getRandomInt(layers[i].images.length);
          let tempName = layers[i].images[randomNumber].name.replace(
            ".png",
            ""
          );
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: layers[i].images[randomNumber].url,
          };
          traitArray.push(tempObj);
        } else if (layers[i].tempName === layer.tempName) {
          if (includedLayersCount === layer.imageAmount[0]) {
            let filterArr = imageArr.filter(
            (item) => item.count <= layer.imageAmount[1]
          );
          let randomNumber = getRandomInt(filterArr.length);
          let tempName = filterArr[randomNumber].name.replace(".png", "");
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: filterArr[randomNumber].url,
          };
          traitArray.push(tempObj);
          filterArr[randomNumber].count = filterArr[randomNumber].count + 1;
          } else {
            let tempName = layers[i].images[layerCount].name.replace(
              ".png",
              ""
            );
            let tempObj = {
              trait_type: layers[i].name,
              value: tempName,
              url: layers[i].images[layerCount].url,
            };
            traitArray.push(tempObj);
            if (layerCount === layers[i].images.length - 1) {
              layerCount = 0;
              includedLayersCount = includedLayersCount + 1;
            } else {
              layerCount = layerCount + 1;
            }
          }
        }
      }
      count = count + 1;
      mainCount = mainCount + 1;
      generatedArr.push(traitArray);
      traitArray = [];
    }

    return resolve();
  });
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const mixGeneratedArray = async (layerArr) => {
  return new Promise((resolve, reject) => {
    let arrCopy = [...layerArr];
    let mixedArr = [];

    console.log('mixing')
    var currentIndex = arrCopy.length,
      temporaryValue,
      randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = arrCopy[currentIndex];
      arrCopy[currentIndex] = arrCopy[randomIndex];
      arrCopy[randomIndex] = temporaryValue;
    }

    return resolve(arrCopy);
  });
};


/*const generatePropability = async (layer, layers) => {
  return new Promise((resolve, reject) => {
    switch (layer.imageRule) {
      case "min":
        return resolve(generateMin(layer, layers));

      case "max":
        return resolve(generateMax(layer, layers));
      default:
        return resolve(generateExact(layer, layers));
    }
  });
};
const generateExact = async (layer, layers) => {
  let layerCount = 0;
  let count = 0;
  let traitArray = [];
  let includedLayers = layer.includedLayers;
  let includedLayersCount = 0;
  return new Promise((resolve, reject) => {
    while (count < layer.amount) {
      for (let i = 0; i < layers.length; i++) {
        if (includedLayers.includes(layers[i].name)) {
          let randomNumber = getRandomInt(layers[i].images.length);
          let tempName = layers[i].images[randomNumber].name.replace(
            ".png",
            ""
          );
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: layers[i].images[randomNumber].url,
          };
          traitArray.push(tempObj);
        } else if (layers[i].name === layer.name) {
          let tempName = layers[i].images[layerCount].name.replace(".png", "");
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: layers[i].images[layerCount].url,
          };
          traitArray.push(tempObj);
          if (layerCount === layers[i].images.length - 1) {
            layerCount = 0;
            includedLayersCount = includedLayersCount + 1;
          } else {
            layerCount = layerCount + 1;
          }
        }
      }
      count = count + 1;
      mainCount = mainCount + 1;
      generatedArr.push(traitArray);
      traitArray = [];
    }
    return resolve();
  });
};

const generateMin = async (layer, layers) => {
  let layerCount = 0;
  let count = 0;
  let traitArray = [];
  let includedLayers = layer.includedLayers;
  let includedLayersCount = 0;
  return new Promise((resolve, reject) => {
    while (count < layer.amount) {
      for (let i = 0; i < layers.length; i++) {
        if (includedLayers.includes(layers[i].name)) {
          let randomNumber = getRandomInt(layers[i].images.length);
          let tempName = layers[i].images[randomNumber].name.replace(
            ".png",
            ""
          );
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: layers[i].images[randomNumber].url,
          };
          traitArray.push(tempObj);
        } else if (layers[i].name === layer.name) {
          if (includedLayersCount === layer.imageAmount) {
            let randomNumber = getRandomInt(layers[i].images.length);
            let tempName = layers[i].images[randomNumber].name.replace(
              ".png",
              ""
            );
            let tempObj = {
              trait_type: layers[i].name,
              value: tempName,
              url: layers[i].images[randomNumber].url,
            };
            traitArray.push(tempObj);
          } else {
            let tempName = layers[i].images[layerCount].name.replace(
              ".png",
              ""
            );
            let tempObj = {
              trait_type: layers[i].name,
              value: tempName,
              url: layers[i].images[layerCount].url,
            };
            traitArray.push(tempObj);
            if (layerCount === layers[i].images.length - 1) {
              layerCount = 0;
              includedLayersCount = includedLayersCount + 1;
            } else {
              layerCount = layerCount + 1;
            }
          }
        }
      }
      count = count + 1;
      mainCount = mainCount + 1;
      generatedArr.push(traitArray);
      traitArray = [];
    }

    return resolve();
  });
};

const generateMax = async (layer, layers) => {
  let imageArr = [];
  for (let i = 0; i < layer.images.length; i++) {
    let imageCopy = { ...layer.images[i] };
    imageArr.push(imageCopy);
  }
  let count = 0;
  let traitArray = [];
  let includedLayers = layer.includedLayers;
  let includedLayersCount = 0;
  return new Promise((resolve, reject) => {
    while (count < layer.amount) {
      for (let i = 0; i < layers.length; i++) {
        if (includedLayers.includes(layers[i].name)) {
          let randomNumber = getRandomInt(layers[i].images.length);
          let tempName = layers[i].images[randomNumber].name.replace(
            ".png",
            ""
          );
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: layers[i].images[randomNumber].url,
          };
          traitArray.push(tempObj);
        } else if (layers[i].name === layer.name) {
          let filterArr = imageArr.filter(
            (item) => item.count <= layer.imageAmount
          );
          let randomNumber = getRandomInt(filterArr.length);
          let tempName = filterArr[randomNumber].name.replace(".png", "");
          let tempObj = {
            trait_type: layers[i].name,
            value: tempName,
            url: filterArr[randomNumber].url,
          };
          traitArray.push(tempObj);
          filterArr[randomNumber].count = filterArr[randomNumber].count + 1;
        }
      }
      count = count + 1;
      mainCount = mainCount + 1;
      generatedArr.push(traitArray);
      traitArray = [];
    }

    return resolve();
  });
};*/
