import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSprings } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import clamp from "lodash.clamp";
import swap from "lodash-move";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {
  updateListIndex,
  setLayers,
  addLayer,
} from "../../../components/redux/reduxSlice";

import "../generator.css";

import { auth } from "../../../components/firebase/firebase";
import CancelButton from "../components/CancelButton";
import NextButton from "../components/NextButton";
import PreviousButton from "../components/PreviousButton";
import LayerComponent from "../components/LayerComponent";
import StepperNav from "../components/StepperNav";
import SaveButton from "../components/SaveButton";

const ColorButton = styled(IconButton)(() => ({
  color: "#dbdbdb",
}));

const fn = (order, active, originalIndex, curIndex, y) => (index) => {
  if (active && index === originalIndex) {
    return {
      y: curIndex * 100 + y,
      scale: 1.1,
      zIndex: 1,
      shadow: 15,
      immediate: (key) => key === "y" || key === "zIndex",
    };
  } else {
    return {
      y: Math.abs(order.indexOf(index) * 100),
      scale: 1,
      zIndex: 0,
      shadow: 1,
      immediate: false,
      listIndex: index,
    };
  }
};

function DraggableList() {
  const layersState = useSelector((state) => state.redux.layers);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const layerCount = useSelector((state) => state.redux.layerCount);
  const dispatch = useDispatch();

  let order2 = layersState.map((_, index) => {
    return _.listIndex;
  });

  const [springs, api] = useSprings(layersState.length, fn(order2)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order2.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 100 + y) / 100),
      0,
      layersState.length - 1
    );
    const newOrder = swap(order2, curIndex, curRow);
    api.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) {
      dispatch(updateListIndex(newOrder));
    }
  });

  return (
    <div className={"LayerContentOuter"}>
      <div className={"LayerContent"}>
        {layersState.map((layer, i) => {
          return (
            <LayerComponent
              layer={layer}
              spring={springs[layer.listIndex]}
              bind={bind}
              key={layer.keyIndex}
              index={layer.listIndex}
            />
          );
        })}
      </div>
    </div>
  );
}

function GeneratorLayers() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const layersState = useSelector((state) => state.redux.layers);
  const dispatch = useDispatch();
  const [stateLayers, setStateLayers] = useState([]);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const layerCount = useSelector((state) => state.redux.layerCount);

  const addLayerToRedux = () => {
    let tempLayer = {};
    tempLayer.name = "";
    tempLayer.tempName = "";
    tempLayer.images = [];
    tempLayer.amount = 0;
    tempLayer.imageRule = "exact";
    tempLayer.includedLayers = [];
    tempLayer.propability = false;
    tempLayer.listIndex = layersState.length;
    tempLayer.keyIndex = layerCount;
    tempLayer.imageAmount = 0
    dispatch(addLayer(tempLayer));
  };

  const removeLayer = () => {};

  const editLayer = () => {};

  useEffect(() => {
    setStateLayers(layersState);
  }, [layersState]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (
      collectionInfo.name === "" ||
      collectionInfo.description === "" ||
      collectionInfo.size < 1
    )
      return navigate("/generator");
  }, [user, loading]);
  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={1} />
      </div>
      <div className="GeneratorAddLayerDiv">
        <ColorButton onClick={addLayerToRedux} variant="contained">
          <AddIcon fontSize="large" />
        </ColorButton>
      </div>
      <div
        className="GeneratorMid"
        style={{ height: stateLayers.length * 100 }}
      >
        {layersState.length > 0 ? <DraggableList /> : null}
      </div>
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
          <CancelButton />
          <SaveButton/>
        </div>
        <div className="GeneratorBottomInner">
          <PreviousButton navigateNext={"/generator"} />
          <NextButton
            disabled={layersState <= 0}
            navigateNext={"/generator/layers/edit"}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneratorLayers;
