import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";

import "../experimental.css";
import { auth } from "../../../components/firebase/firebase";
import {createVideo} from '../../../components/generate/generateVideo'


function ExperimentalStart() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();


  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="ExperimentalInner">
      <button
            className="StartButton"
            onClick={() => createVideo()}
          >
            Generate GIF
          </button>
    </div>
  );
}

export default ExperimentalStart;
