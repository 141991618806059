import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "../generator.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { auth } from "../../../components/firebase/firebase";
import { removeLayer, updateLayer, updatePropability } from "../../../components/redux/reduxSlice";

import TotalAmountSlider from "../components/TotalAmountSlider";
import AmountSlider from "../components/AmountSlider";
import CancelButton from "../components/CancelButton";
import NextButton from "../components/NextButton";
import PreviousButton from "../components/PreviousButton";
import ImageDrop from "../components/ImageDrop";
import StepperNav from "../components/StepperNav";
import SaveButton from "../components/SaveButton";

import { Navigation, Pagination } from "swiper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditLayers(props) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const layersState = useSelector((state) => state.redux.layers);
  const totalCount = useSelector((state) => state.redux.totalCount);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();

  const checkLayers = () => {
    if (layersState.length > 0) {
      for (let i = 0; i < layersState.length; i++) {
        if (layersState[i].images.length < 1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (value, layer) => {
    let tempLayer = {...layer}
    tempLayer.includedLayers = typeof value === 'string' ? value.split(',') : value;

    dispatch(updateLayer(tempLayer))

    /*setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );*/
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if (layersState < 1) return navigate('/generator/layers/add')
  }, [user, loading, layersState]);

  return (
    <div className="GeneratorInner">
      <div className="GeneratorTop">
        <StepperNav activeLayer={2}/>
      </div>
      <div className="GeneratorMid">
        
        <Swiper
          navigation={true}
          loop={true}
          allowTouchMove={false}
          pagination={true}
          modules={[Navigation, Pagination]}
          className="SublayerSwiper"
        >
          {layersState.map((layer, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="SublayerSwiperOuter">
                  <div className="SublayerSwiperTop">
                    <p className="SublayerSwiperTitle">{layer.tempName !== '' ? (layer.tempName): (layer.name)}</p>
                    <p className="SublayerSwiperCount">Images available: {collectionInfo.size - totalCount} Images used: {totalCount}</p>
                  </div>
                  <div className="SublayerSwiperInner">
                      <ImageDrop images={layer.images} layer={layer}/>
                  </div>
                  {layer.images.length > 0 ? (
                      <div className="GeneratorPropability">
                    <div className="SublayerSwiperSubTop">
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={layer.propability}
                            onChange={(event) =>
                              dispatch(
                                updatePropability({
                                  ...layer,
                                  propability: event.target.checked,
                                })
                              )
                            }
                          />
                        }
                        label="Probability"
                      />
                      <div className="SublayerSwiperSubTopSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Included Layers
                          </InputLabel>
                          <Select
                            focused="true"
                            className="LayerSelect"
                            multiple
                            disabled={layer.propability === false}
                            value={layer.includedLayers}
                            onChange={(event)=>handleChange(event.target.value, layer)}
                            input={<OutlinedInput label="Included Layers" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {layersState
                              .filter((item) => item.propability !== true)
                              .filter((item) => item.tempName !== layer.tempName)
                              .map((name) => (
                                <MenuItem key={name.listIndex} value={name.tempName}>
                                  <Checkbox
                                    checked={
                                      layer.includedLayers.indexOf(name.tempName) >
                                      -1
                                    }
                                  />
                                  <ListItemText primary={name.tempName} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="SublayerSwiperSubTop">
                        <AmountSlider layer={layer}/>
                      <TotalAmountSlider layer={layer} />
                    </div>
                  </div>
                  ):(
                      null
                  )}
                  
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="GeneratorBottom">
        <div className="GeneratorBottomInner">
          <CancelButton />
          <SaveButton/>
        </div>
        <div className="GeneratorBottomInner">
           <PreviousButton navigateNext={"/generator/layers/add"} />
          <NextButton disabled={checkLayers()} navigateNext={"/generator/upload"} />
        </div>
      </div>
    </div>
  );
}

export default EditLayers;
