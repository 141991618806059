import { createSlice, applyMiddleware } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

const initialState = {
  layerCount: 0,
  totalCount: 0,
  layers: [],
  generateState: {
    loading: false,
    loadingZip: false,
    loadingMessage: "",
    loadingImage: 0,
    loadingProgress: 0,
    zipImages: 500,
    zipFileCount: 0,
  },
  loadingImages: [],
  collectionInfo: {
    name: "",
    size: 100,
    description: "",
    ipfs: false,
    ipfsProvider: "",
    ipfsProviderKey: "",
    metadata: true,
    rarity: false,
    cloud: false,
    cloudProvider: "",
    smartContract: false,
    blockchain: "",
    imageOpensea: true,
    imageThumb: false,
    imageWeb: false,
    imageOg: false,
    motionWebM: false,
    motionMp4: false,
    motionGif: false,
  },
};

export const reduxSplice = createSlice(
  {
    name: "redux",
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      // Use the PayloadAction type to declare the contents of `action.payload`
      setCollectionInfo: (state, action) => {
        state.collectionInfo = action.payload;
      },
      setLoading: (state, action) => {
        state.generateState = action.payload;
      },
      setLoadingImages: (state, action) => {
        if (state.loadingImages.length < 12) {
          state.loadingImages.push(action.payload);
        } else {
          const arrCopy = [...state.loadingImages];
          arrCopy.shift();
          arrCopy.push(action.payload);
          state.loadingImages = arrCopy;
        }
      },

      resetLoadingImages: (state) => {
        state.loadingImages = [];
      },
      setLayers: (state, action) => {
        state.layers = action.payload;
        state.totalCount = calculateTotalCount(state.layers);
      },
      addLayer: (state, action) => {
        state.layers.push(action.payload);
        state.layerCount = state.layerCount + 1;
        state.totalCount = calculateTotalCount(state.layers);
      },
      removeLayer: (state, action) => {
        let tempState = [...state.layers];
        let tempLayersFilter = tempState.filter(
          (item) => item.listIndex !== action.payload
        );
        for (let i = 0; i < tempLayersFilter.length; i++) {
          tempLayersFilter[i].listIndex = i;
        }
        state.layers = tempLayersFilter;
        state.totalCount = calculateTotalCount(state.layers);
      },
      updateLayer: (state, action) => {
        let tempState = [...state.layers];
        tempState.splice(action.payload.listIndex, 1, action.payload);
        state.layers = tempState;
        state.totalCount = calculateTotalCount(state.layers);
      },

      updatePropability: (state, action) => {
        let tempState = [...state.layers];
        if (action.payload.propability === false) {
          tempState.splice(action.payload.listIndex, 1, action.payload);
        } else {
          tempState.splice(action.payload.listIndex, 1, action.payload);
          for (let i = 0; i < tempState.length; i++) {
            let tempArray = [...tempState[i].includedLayers];
            for (let j = 0; j < tempArray.length; j++) {
              if (tempArray[j] === action.payload.name) {
                tempState[i].includedLayers.splice(j, 1);
              }
            }
          }
        }

        state.layers = tempState;
      },

      updateImageAmount: (state, action) => {
        let tempState = [...state.layers];
        switch (action.payload.imageRule) {
          case "exact":
            tempState.splice(action.payload.listIndex, 1, {
              ...action.payload,
              amount: action.payload.imageAmount * action.payload.images.length,
            });
            break;
          default:
            tempState.splice(action.payload.listIndex, 1, action.payload);
        }
        state.layers = tempState;
        state.totalCount = calculateTotalCount(state.layers);
      },
      updateListIndex: (state, action) => {
        const newArray = action.payload;
        let tempArray = [];
        for (let i = 0; i < newArray.length; i++) {
          const layer = state.layers.find(
            (layer) => layer.listIndex === newArray[i]
          );
          if (layer) {
            tempArray.push(layer);
          }
        }
        state.layers = tempArray;
        state.totalCount = calculateTotalCount(state.layers);
      },

      addImages: (state, action) => {
        const { keyIndex, imageArr } = action.payload;
        const layer = state.layers.find(
          (layer) => layer.listIndex === keyIndex
        );
        if (layer) {
          layer.images = imageArr;
        }
        state.totalCount = calculateTotalCount(state.layers);
      },
      resetState: (state) => {
        state.layerCount = 0;

        state.totalCount = 0;
        state.layers = [];
        state.generateState = {
          loading: false,
          loadingZip: false,
          loadingMessage: "",
          loadingImage: 0,
          loadingProgress: 0,
          zipImages: 500,
          zipFileCount: 0,
        };
        state.loadingImages = [];
        state.collectionInfo = {
          name: "",
          size: 100,
          description: "",
          ipfs: false,
          ipfsProvider: "",
          ipfsProviderKey: "",
          metadata: false,
          rarity: false,
          cloud: false,
          cloudProvider: "",
          smartContract: false,
          blockchain: "",
          imageOpensea: true,
          imageThumb: false,
          imageWeb: false,
          imageOg: false,
        };
      },
    },
  },
  applyMiddleware(thunk)
);

export const {
  setCollectionInfo,
  setSelectedAmount,
  setLayers,
  addLayer,
  removeLayer,
  updateLayer,
  updateListIndex,
  addImages,
  setCollectionName,
  setGenerating,
  setGenProcess,
  updatePropability,
  updateImageAmount,
  setLoading,
  setLoadingImages,
  resetLoadingImages,
  resetState,
} = reduxSplice.actions;

const calculateTotalCount = (array) => {
  let count = 0;
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      count = count + Number(array[i].amount);
    }
    return count;
  } else {
    return count;
  }
};

export default reduxSplice.reducer;
