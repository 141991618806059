import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import LayersIcon from "@mui/icons-material/Layers";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  removeLayer,
  updateLayer,
  updateImageAmount,
} from "../../../components/redux/reduxSlice";

const Input = styled(MuiInput)`
  max-width: 62px;
`;

export default function AmountSlider(props) {
   
  const layersState = useSelector((state) => state.redux.layers);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();
   const [count, setCount] = useState(props.layer.imageAmount);


  const handleChange = (event, newValue) => {
    console.log('setValue: '+newValue)
    setCount(newValue);
  };

  useEffect(() => {
    if (props.layer.imageAmount !== count) {
      setCount(props.layer.imageAmount);
    }
  }, [props.layer.imageAmount]);

  return (
    <Box sx={{ width: "46%", marginBottom: "20px" }}>
      <Typography id="input-slider" gutterBottom>
        Attribute Count
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LayersIcon />
        </Grid>
        <Grid item xs>
          <Slider
            disabled={props.layer.propability === false}
            min={0}
            max={Number(collectionInfo.size) / props.layer.images.length}
            value={count}
            valueLabelDisplay="auto"
            onChange={handleChange}
            onChangeCommitted={(event) =>
              dispatch(
                updateImageAmount({
                  ...props.layer,
                  imageAmount: count,
                })
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
