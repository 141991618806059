import * as React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import "../generator.css";

import { resetState } from "../../../components/redux/reduxSlice";

const ColorButton = styled(Button)(() => ({
  color: "#369ac9",
  margin: "20px",
  backgroundColor: "#dbdbdb",
  "&:hover": {
    backgroundColor: "#bcbbbb",
  },
}));

export default function SaveButton(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <ColorButton
      disabled
      variant="contained"
    >
      Save
    </ColorButton>
  );
}
