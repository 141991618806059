import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";

import "./generator.css";
import { auth } from "../../components/firebase/firebase";

import GeneratorStart from "./views/GeneratorStart";
import GeneratorLayers from "./views/GeneratorLayers";
import EditLayers from "./views/EditLayers";
import GeneratorIpfs from "./views/GeneratorIpfs";
import GeneratorContract from "./views/GeneratorContract";
import InitGenerate from "./views/InitGenerate";

function Generator() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <div className="Generator">
      <Routes>
        <Route path="/" element={<GeneratorStart />} />
        <Route path="/layers/add" element={<GeneratorLayers />} />
        <Route path="/layers/edit" element={<EditLayers />} />
        <Route path="/upload" element={<GeneratorIpfs />} />
        <Route path="/generate" element={<InitGenerate />} />
      </Routes>
    </div>
  );
}

export default Generator;
