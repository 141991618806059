import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import "../metadata.css";
import { auth } from "../../../components/firebase/firebase";

var JSZip = require("jszip");

const CustomCheckbox = styled(Checkbox)({
  color: "#dbdbdb",
  flex: 1,
  "&.Mui-checked": {
    color: "#dbdbdb",
  },
});

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  height: "120px",
  fontSize: "80%",
  textAlign: "center",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function MetadataStart() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jsonData, setJsonData] = useState([]);
  const [ipfsData, setIpfsData] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [hiddenData, setHiddenData] = useState("");
  const [hiddenStart, setHiddenStart] = useState(0);
  const [generating, setGenerating] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const fileReader = new FileReader();
    fileReader.readAsText(acceptedFiles[0], "UTF-8");
    fileReader.onload = (e) => {
      let parsedData = JSON.parse(e.target.result);
      console.log(parsedData);
      setJsonData(parsedData);
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".json" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  const handleClick = async () => {
    setGenerating(true);
    let zip = new JSZip();

    for (let i = 0; i < jsonData.length; i++) {
      let tempObject = jsonData[i];
      let tempColor;

      let newMetadataObj;

      if (isHidden === true && Number(hiddenStart) <= i) {
        newMetadataObj = {
          name: tempObject.name,
          description: tempObject.description,
          image: hiddenData,
          attributes: [{ trait_type: "Hidden", value: "True" }],
        };
      } else {
        newMetadataObj = {
          name: tempObject.name,
          description: tempObject.description,
          image: ipfsData + "" + i + ".png",
          attributes: tempObject.attributes,
        };
      }
      zip.file(
        `metadata/${i}.json`,
        JSON.stringify(newMetadataObj, null, 2)
      );

      if (jsonData.length - 1 === i) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          setGenerating(false);
          saveAs(content, `metadata.zip`);
        });
      }
    }
  };

  console.log(ipfsData)
  console.log(hiddenData)
  console.log(hiddenStart)
  console.log(isHidden)

  return (
    <div className="MetadataInner">
      <Box sx={{ width: "70%" }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop Metadata JSON-file here!</p>
        </div>
      </Box>
      <TextField
        sx={{ width: "70%", marginTop: "30px", marginBottom: "15px" }}
        fullWidth
        onChange={(event) => setIpfsData(event.target.value)}
        id="outlined-basic"
        label="IPFS Image Address"
        variant="outlined"
        value={ipfsData}
      />
      <div className="HiddenDiv">
        <FormControlLabel
          sx={{
            color: "#dbdbdb",
            "& .MuiFormControlLabel-label": {
              fontSize: 16,
              color: "#dbdbdb",
            },
          }}
          control={<CustomCheckbox checked={isHidden} onChange={() => setIsHidden(!isHidden)} />}
          label="Hidden"
        />
        <TextField
          sx={{  marginTop: "30px", marginBottom: "15px" }}
        onChange={(event) => setHiddenStart(event.target.value)}
          id="outlined-basic"
          label="Hidden Token Start"
          variant="outlined"
          type="number"
          disabled={isHidden === false}
          value={hiddenStart}
        />
      </div>
      <TextField
        sx={{ width: "70%", marginTop: "30px", marginBottom: "15px" }}
        fullWidth
        onChange={(event) => setHiddenData(event.target.value)}
        id="outlined-basic"
        label="Hidden Image Address"
        variant="outlined"
          disabled={isHidden === false}
        value={hiddenData}
      />
      <Button
        variant="text"
        sx={{ color: "#fff" }}
        onClick={handleClick}
        disabled={ipfsData === ""}
      >
        Generate Metadata
      </Button>
    </div>
  );
}

export default MetadataStart;
