import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import LayersIcon from "@mui/icons-material/Layers";
import { useSelector, useDispatch } from "react-redux";
import { removeLayer, updateLayer } from "../../../components/redux/reduxSlice";

const Input = styled(MuiInput)`
  max-width: 62px;
`;

export default function TotalAmountSlider(props) {
  const [count, setCount] = useState(0);
  const [min, setMin] = useState(0);
  const layersState = useSelector((state) => state.redux.layers);
  const collectionInfo = useSelector((state) => state.redux.collectionInfo);
  const dispatch = useDispatch();

  const [max, setMax] = useState(Number(collectionInfo.size));

  const handleBlur = () => {
    if (props.layer.amount < 0) {
      dispatch(
        updateLayer({
          ...props.layer,
          amount: 0,
        })
      );
    } else if (props.layer.amount > Number(collectionInfo.size)) {
      dispatch(
        updateLayer({
          ...props.layer,
          amount: Number(collectionInfo.size),
        })
      );
    }
  };

  const calculateMin = () => {
    setMin(props.layer.images.length * Number(props.layer.imageAmount));
    if (
      Number(props.layer.amount) <
      props.layer.images.length * Number(props.layer.imageAmount)
    ) {
      dispatch(
        updateLayer({
          ...props.layer,
          amount: props.layer.images.length * Number(props.layer.imageAmount),
        })
      );
    }
  };

  const calculateMax = () => {
    setMax(Number(collectionInfo.size));
    if (
      props.layer.amount >
      Number(collectionInfo.size)
    ) {
      dispatch(
        updateLayer({
          ...props.layer,
          amount: Number(collectionInfo.size),
        })
      );
    }
  };

  useEffect(() => {
    calculateMax();
    calculateMin();
  }, [props.layer]);


  return (
    <Box sx={{ width: "46%", marginBottom: "20px" }}>
      <Typography id="input-slider" gutterBottom>
        Total Count
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LayersIcon />
        </Grid>
        <Grid item xs>
          <Slider
            disabled={
              props.layer.propability === false
            }
            min={min}
            valueLabelDisplay="auto"
            max={max}
            value={count}
            onChange={(event) => setCount(event.target.value)}
            onChangeCommitted={(event) =>
              dispatch(updateLayer({ ...props.layer, amount: count }))
            }
          />
        </Grid>
        
      </Grid>
    </Box>
  );
}
